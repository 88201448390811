import type { AuthorizationType } from '@redux/features/authorization/types/authorization'
import type { UserType } from '@redux/features/authorization/types/login'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { AuthorizationStep } from '@components/authorization/constant/steps'

const initialState: AuthorizationType = {
  currentStep: AuthorizationStep.Login,
  email: '',
  isCheckedEmail: false,
  isLoadingAuth: false,
  isLoggedIn: false,
  isOpenAuthorizationPopup: false,
}

export const authenticationSlice = createSlice({
  initialState,
  name: 'authorization',

  reducers: {
    saveEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setCurrentStep: (state, action: PayloadAction<AuthorizationStep>) => {
      state.currentStep = action.payload
    },
    setExcludePrivacy: (state, action: PayloadAction<boolean>) => {
      state.isExcludePrivacy = action.payload
    },
    setIsCheckedEmail: (state, action: PayloadAction<boolean>) => {
      state.isCheckedEmail = action.payload
    },
    setIsLoadingAuth: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAuth = action.payload
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    setIsOpenAuthorizationPopup: (state, action: PayloadAction<boolean>) => {
      state.isOpenAuthorizationPopup = action.payload
    },
    setMessageLogin: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    setUser: (state, action: PayloadAction<null | UserType>) => {
      state.user = action.payload
      state.isLoggedIn = !!action.payload
    },
  },
})

export const {
  saveEmail,
  setCurrentStep,
  setExcludePrivacy,
  setIsCheckedEmail,
  setIsLoadingAuth,
  setIsLoggedIn,
  setIsOpenAuthorizationPopup,
  setMessageLogin,
  setUser,
} = authenticationSlice.actions

export default authenticationSlice.reducer
