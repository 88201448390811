import dayjs from 'dayjs'

import type { ITrain } from '@Types/routes/train'

export const getCheapestTrainNumber = (trains: Record<string, ITrain>) => {
  const trainsArray = Object.entries(trains)
  let cheapest = trainsArray[0][1]
  trainsArray.forEach(([, train]) => {
    const trainPrice = train.cheapest_coach_class?.cheapest_fare.price?.number
    const cheapestPrice = cheapest.cheapest_coach_class?.cheapest_fare.price?.number
    if (!cheapestPrice || (trainPrice && trainPrice < cheapestPrice)) {
      cheapest = train
    }
  })

  //check is cheap price have other train
  const cheapestPrice = cheapest.cheapest_coach_class?.cheapest_fare.price?.number
  for (const [, train] of trainsArray) {
    const trainPrice = train.cheapest_coach_class?.cheapest_fare.price?.number
    if (cheapestPrice && trainPrice && trainPrice === cheapestPrice && train.train_number !== cheapest.train_number) {
      return undefined
    }
  }

  return cheapest.train_number || undefined
}

export const filterTrains = ({
  arrivalDatetime,
  trains,
}: {
  arrivalDatetime?: string
  trains: [] | Record<string, ITrain>
}) => {
  if (!arrivalDatetime) return trains
  const arrival = dayjs(arrivalDatetime).add(30, 'minute')

  const filteredTrain = Object.entries(trains).filter(([, train]) => {
    const departure = dayjs(train.departure_datetime)
    return departure.isAfter(arrival)
  })

  return filteredTrain.length ? Object.fromEntries(filteredTrain) : ([] as [])
}
