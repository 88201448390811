import { createContext } from 'react'

import type { WelcomePageServerSidePropsType } from '@redux/features/welcomPage/getServerSideProps'

import type { DefaultServerSidePropsType } from '@utils/serverSideProps/getDefaultServerSideProps'

export default createContext<DefaultServerSidePropsType | WelcomePageServerSidePropsType>({
  isMobile: false,
  isTablet: false,
})
