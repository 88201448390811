import type { FieldData } from 'rc-field-form/es/interface'

import { setCurrentStep } from '@redux/features/authorization/authorization.slice'
import type { CheckEmailType, ResponseCheckEmailType } from '@redux/features/authorization/types/login'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryMeta, MutationDefinition } from '@reduxjs/toolkit/query'

import type { ApiResponseErrorType } from '@Types/api/responseError'
import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import type { RegistrationCheckEmailFormType } from '@utils/forms/authorization/registration'
import { RegistrationFieldsName } from '@utils/forms/authorization/registration'

type PostCheckEmailType = MutationTrigger<
  MutationDefinition<
    CheckEmailType,
    BaseQueryFn<FetchArgs | string, unknown, ApiResponseErrorType, NonNullable<unknown>, FetchBaseQueryMeta>,
    'checkEmail',
    ResponseCheckEmailType,
    'api'
  >
>

export default createAsyncThunk<
  void,
  {
    data: RegistrationCheckEmailFormType
    postCheckEmail: PostCheckEmailType
    setErrors: (fields: FieldData[]) => void
  },
  { state: RootState }
>('authorization/registrationCheckEmail', ({ data, postCheckEmail, setErrors }, { dispatch }) => {
  postCheckEmail({ agree_policy: 1, email: data.email || '' })
    .unwrap()
    .then(response => {
      const { message, success } = response

      if (success) {
        dispatch(setCurrentStep(AuthorizationStep.LoginExtended))
      } else {
        setErrors([{ errors: [message], name: RegistrationFieldsName.Email }])
      }
    })
    .catch((error: ResponseErrorType) => {
      if (error.status === 404) {
        dispatch(setCurrentStep(AuthorizationStep.Registration))
      } else {
        setErrors([{ errors: [error.data.message], name: RegistrationFieldsName.Email }])
      }
    })
})
