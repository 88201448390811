import type { FieldData } from 'rc-field-form/es/interface'

import {
  saveEmail,
  setCurrentStep,
  setIsCheckedEmail,
  setIsLoggedIn,
  setIsOpenAuthorizationPopup,
  setUser,
} from '@redux/features/authorization/authorization.slice'
import type { LoginType, PostLoginType } from '@redux/features/authorization/types/login'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import { LoginFieldsName } from '@utils/forms/authorization/login'

export default createAsyncThunk<
  void,
  { loginForm: LoginType; postLogin: PostLoginType; setErrors: (fields: FieldData[]) => void },
  { state: RootState }
>('authorization/login', ({ loginForm, postLogin, setErrors }, { dispatch }) => {
  postLogin(loginForm)
    .unwrap()
    .then(response => {
      dispatch(setUser(response))
      dispatch(saveEmail(''))
      dispatch(setIsLoggedIn(true))
      dispatch(setIsOpenAuthorizationPopup(false))
      dispatch(setCurrentStep(AuthorizationStep.Login))
      dispatch(setIsCheckedEmail(false))
    })
    .catch((error: ResponseErrorType) => {
      setErrors([{ errors: [error.data.message], name: LoginFieldsName.Password }])
    })
})
