import type { FieldData } from 'rc-field-form/es/interface'

import { setCurrentStep } from '@redux/features/authorization/authorization.slice'
import type { AccountResetResponseType } from '@redux/features/authorization/types/accountReset'
import { setNotification } from '@redux/features/modals/modals.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryMeta, MutationDefinition } from '@reduxjs/toolkit/query'

import type { ApiResponseErrorType } from '@Types/api/responseError'
import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import type { AccountPasswordFormType } from '@utils/forms/authorization/resetPassword'
import { ResetAccountFieldsName } from '@utils/forms/authorization/resetPassword'

type PostAccountResetType = MutationTrigger<
  MutationDefinition<
    AccountPasswordFormType,
    BaseQueryFn<FetchArgs | string, unknown, ApiResponseErrorType, NonNullable<unknown>, FetchBaseQueryMeta>,
    'resetAccount',
    AccountResetResponseType,
    'api'
  >
>

export default createAsyncThunk<
  void,
  {
    data: AccountPasswordFormType
    postAccountReset: PostAccountResetType
    setErrors: (fields: FieldData[]) => void
  },
  { state: RootState }
>('authorization/resetAccount', ({ data, postAccountReset, setErrors }, { dispatch }) => {
  postAccountReset(data)
    .unwrap()
    .then(() => {
      dispatch(setCurrentStep(AuthorizationStep.Notification))
    })
    .catch((error: ResponseErrorType) => {
      if (error.status === 404) {
        setErrors([{ errors: [error.data.message], name: ResetAccountFieldsName.Email }])
      } else {
        dispatch(
          setNotification({
            description: error.data?.message,
            message: 'somethingWrong',
            type: 'error',
          })
        )
      }
    })
})
