import type { IRoute } from '@Types/routes/route'

export const getFirstTrainData = (trains: IRoute['trains']) => {
  const [firstTrainKey, firstTrain] = Object.entries(trains)[0]
  const coachClass = firstTrain.cheapest_coach_class || firstTrain.coach_classes[0]

  return {
    coachClass,
    fare: coachClass.cheapest_fare || coachClass.fares[0],
    train: firstTrain,
    trainKey: firstTrainKey,
  }
}
