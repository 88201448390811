import { api } from '@redux/api/api'
import type { DataClientDetailsType, PostClientDetailsType } from '@redux/features/preferences/types/clientDetails'
import type {
  GetSeatPreferenceInfoType,
  PostSeatPreferenceType,
  ResponseSeatPreferenceInfoType,
} from '@redux/features/preferences/types/seatPreference'

import { urlVersionPrefixV2 } from '@constants/api/api'

const preferencesApi = api.injectEndpoints({
  endpoints: build => ({
    getClientDetails: build.query<DataClientDetailsType, string>({
      query: hash => ({
        method: 'GET',
        url: urlVersionPrefixV2 + `booking/${hash}/client-details`,
      }),
    }),
    getCurrentSeatPreference: build.query<ResponseSeatPreferenceInfoType, GetSeatPreferenceInfoType>({
      query: ({ hash, leg }) => ({
        method: 'GET',
        url: urlVersionPrefixV2 + `booking/${hash}/${leg}/seat-preference`,
      }),
    }),
    getSeatPreferenceInfo: build.query<ResponseSeatPreferenceInfoType, GetSeatPreferenceInfoType>({
      query: ({ hash, leg }) => ({
        method: 'GET',
        url: urlVersionPrefixV2 + `booking/${hash}/${leg}/seat-preference/info`,
      }),
    }),
    postClientDetails: build.mutation<void, PostClientDetailsType>({
      query: ({ data, hash }) => ({
        body: data,
        method: 'POST',
        url: urlVersionPrefixV2 + `booking/${hash}/client-details`,
      }),
    }),
    postSeatPreference: build.mutation<void, PostSeatPreferenceType>({
      query: ({ hash, leg, seatPreferenceIds }) => ({
        body: seatPreferenceIds,
        method: 'POST',
        url: urlVersionPrefixV2 + `booking/${hash}/${leg}/seat-preference`,
      }),
    }),
  }),
})

export const {
  endpoints: preferencesEndpoints,
  useGetSeatPreferenceInfoQuery,
  usePostClientDetailsMutation,
  usePostSeatPreferenceMutation,
} = preferencesApi
