import { authorizationEndpoints } from '@redux/features/authorization/authorization.api'
import { setIsLoggedIn, setUser } from '@redux/features/authorization/authorization.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

export default createAsyncThunk<void, void, { state: RootState }>('authorization/logout', (data, { dispatch }) => {
  void dispatch(authorizationEndpoints.postLogout.initiate()).finally(() => {
    dispatch(setUser(null))
    dispatch(setIsLoggedIn(false))
  })
})
