import OrangeThemeToken from '../design-tokens/Tokens.orange.tokens.json'
import type { Theme } from '../../types/themes/theme'
import { commonTheme } from '../commonTheme/commonTheme'

const orangeTheme: Theme = {
  colors: OrangeThemeToken,
  ...commonTheme,
}

export default orangeTheme
