import { TrainFilters } from '@redux/features/trainFilters/constants/trainFilters.constants'
import type {
  FiltersType,
  RangeValueType,
  StationFilterType,
  TimeOfDayType,
  TravelClassFilterType,
} from '@redux/features/trainFilters/types/trainFiltersState'
import { checkTimeslot, getHour } from '@redux/features/trainFilters/utils/timeOfDay'

import type { IRoute } from '@Types/routes/route'
import type { ITrain } from '@Types/routes/train'
import { getAllCheapestPrices, getAllPrices } from '@utils/price/price'

export const filterTrains = (trains: IRoute['trains'], filters: FiltersType, exclude?: TrainFilters[]) => {
  trains = exclude?.includes(TrainFilters.TRAVEL_TIME) ? trains : filterByTravelTime(trains, filters.travelTime)
  trains = exclude?.includes(TrainFilters.PRICE) ? trains : filterByPrice(trains, filters.price)
  trains = exclude?.includes(TrainFilters.ARRIVAL_TIME)
    ? trains
    : filterByTimeOfDay(trains, filters.arrivalTime, TrainFilters.ARRIVAL_TIME)
  trains = exclude?.includes(TrainFilters.DEPARTURE_TIME)
    ? trains
    : filterByTimeOfDay(trains, filters.departureTime, TrainFilters.DEPARTURE_TIME)
  trains = exclude?.includes(TrainFilters.ARRIVAL_STATION)
    ? trains
    : filterByStation(trains, filters[TrainFilters.ARRIVAL_STATION], TrainFilters.ARRIVAL_STATION)
  trains = exclude?.includes(TrainFilters.DEPARTURE_STATION)
    ? trains
    : filterByStation(trains, filters.departureStation, TrainFilters.DEPARTURE_STATION)
  trains = exclude?.includes(TrainFilters.TRAVEL_CLASS) ? trains : filterByTravelClass(trains, filters.travelClass)

  return trains
}

export const filterByStation = (
  trains: IRoute['trains'],
  filter: StationFilterType[],
  name: TrainFilters.ARRIVAL_STATION | TrainFilters.DEPARTURE_STATION
) => {
  const path = name === TrainFilters.ARRIVAL_STATION ? 'arrival_station' : 'departure_station'
  const activeStations = filter.filter(station => station.value).map(station => station.name)

  if (!activeStations.length) return trains

  return Object.entries(trains).reduce((result: Record<string, ITrain>, [key, train]) => {
    if (activeStations.includes(train[path].single_name)) {
      result[key] = train
    }
    return result
  }, {})
}

const filterByPrice = (trains: IRoute['trains'], value: RangeValueType) => {
  return Object.entries(trains).reduce((result: Record<string, ITrain>, [key, train]) => {
    const prices = [...getAllPrices({ [key]: train }), ...getAllCheapestPrices({ [key]: train })]
    if (prices.some(number => number >= value[0] && number <= value[1])) {
      result[key] = train
    }
    return result
  }, {})
}

const filterByTravelTime = (trains: IRoute['trains'], value: RangeValueType) => {
  return Object.entries(trains).reduce((result: Record<string, ITrain>, [key, train]) => {
    if (train.running_time >= value[0] && train.running_time <= value[1]) {
      result[key] = train
    }
    return result
  }, {})
}

const filterByTravelClass = (trains: IRoute['trains'], value: TravelClassFilterType[]) => {
  const activeClasses = value.filter(item => item.value)
  const activeClassesNames = new Map(activeClasses.map(cls => [cls.name, cls.value]))

  if (!activeClassesNames.size) return trains

  Object.entries(trains).forEach(([key, train]) => {
    if (!train.coach_classes.some(el => activeClassesNames.has(el.coach_class.name))) {
      delete (trains as Record<string, ITrain>)[key]
    }
  })

  return trains
}

export const filterByTimeOfDay = (
  trains: IRoute['trains'],
  filters: TimeOfDayType[],
  name: TrainFilters.ARRIVAL_TIME | TrainFilters.DEPARTURE_TIME
) => {
  const checkedTimes = filters.filter(item => item.value)
  if (!isFilteringNecessary(checkedTimes.length, filters.length)) {
    return trains
  }
  return Object.entries(trains).reduce((result: Record<string, ITrain>, [key, train]) => {
    const hour = getHour(name === TrainFilters.ARRIVAL_TIME ? train.arrival_datetime : train.departure_datetime)

    if (checkTimeslot(checkedTimes, hour)) {
      result[key] = train
    }
    return result
  }, {})
}

const isFilteringNecessary = (checkedItemsLength: number, totalFiltersLength: number) => {
  return !(checkedItemsLength === 0 || checkedItemsLength === totalFiltersLength)
}
